import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import GridContainer from "components/Grid/GridContainer.js";
import CardPlano from "components/Card/CardPlano";
import Button from "@material-ui/core/Button";
import LoadingGrid from "components/Loading/LoadingGrid.js";
import Fab from "@material-ui/core/Fab";
import Checkbox from "@material-ui/core/Checkbox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import api from "services/api.js";
import { serverApi } from "services/constante.js";
import MeuPlanoAtual from "components/Uteis/MeuPlanoAtual";
import GridItem from "components/Grid/GridItem.js";

const styles = {
  headerTitle: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    marginTop: 10,
    fontWeight: 400
  },
  messageInfos: {
    fontFamily: "Montserrat, sans-serif"
  },
  cardCategory: {
    fontFamily: "Montserrat, sans-serif",
    color: "#999494"
  },
  cardTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500
  },
  borderCard: {
    borderTop: "5px solid #03A9F4!important"
  },
  precoPE: {
    fontFamily: "Montserrat, sans-serif",
    marginBottom: 0,
    textAlign: "right",
    fontSize: "17px",
    color: "#03A9F4"
  },
  fabButton: {
    position: "fixed",
    right: "40%",
    bottom: "60px",
    width: "250px",
    backgroundColor: "#4caf50",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#53c857",
      color: "#FFFFFF"
    }
  },
  fabButtonMobile: {
    position: "fixed",
    right: "20%",
    bottom: "25px",
    width: "250px",
    backgroundColor: "#4caf50",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#53c857",
      color: "#FFFFFF"
    }
  },
  btnPrincipal: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 900,
    color: '#FFF',
    textAlign: 'center',
    textTransform: 'uppercase',
    display: 'block',
    paddingTop: 30,
    paddingBottom: 30,
    '&:hover': {
        color: '#FFF'
    }
  },
  btnStyle: {
    backgroundColor: '#00acc1',
    width: '50%',
    margin: '0 auto',
    borderRadius: '5px',
    padding: '5px'
  },
};

const CustomButton = withStyles(() => ({
  root: {
    color: "#FFF",
    backgroundColor: "#4caf50",
    width: "100%",
    "&:hover": {
      backgroundColor: "#559258"
    }
  }
}))(Button);

const BlueCheckbox = withStyles({
  root: {
    color: "#03A9F4",
    padding: "0 7px 0 0",
    "&$checked": {
      color: "#03A9F4"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(styles);

export default function ProdutosExtras(props) {
  const classes = useStyles();
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [showBTNFinalizar, setShowBTNFinalizar] = useState(false);
  const [pe, setPE] = useState([]); // Produtos Extras
  const [guidsPE, setGuidsPE] = useState(new Map()); // Guids Produtos Extras
  const [quantPE, setQuantPE] = useState(new Map());
  const [checkboxPE, setCheckboxPE] = useState(new Map());

  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    produtosExtrass();
  }, [props]);

  async function produtosExtrass() {
    setLoadingGrid(true);
    await api.get(serverApi.url + "/api/appUsuario/v1/ProdutoExtra/Todos")
      .then(function(response) {
        //console.log("Extras", response);
        //let extrasFake = new Array();
        //extrasFake.push({Guid: "fsdfsd-g4455jjnbsd-hhffgf", NomeProduto: "Produto Teste", DescricaoProduto: "Aproveite esse plus", ValorProduto: 10.16});
        //extrasFake.push({Guid: "JFDSJFSDJ-5HDK9KLFM-FDSHFNDS-MBNCV", NomeProduto: "5 Habilitações", DescricaoProduto: "5 habilitações extras", ValorProduto: 49.90});
        //console.log("Extras FAKE", extrasFake);
        //setPE(extrasFake);
        setPE(response.data);
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => {
        setLoadingGrid(false);
      });
  }

  const handleQuantityChange = (id, quantity) => {
    let itemsQnt = quantPE;
    itemsQnt.set(id, quantity);
    setQuantPE(itemsQnt);
  };

  function handleChangeCheckbox(isChecked, item) {
    //const valor = e.target.value;

    let itemsGuids = guidsPE;
    let itemsQnt = quantPE;
    if (isChecked) {
      itemsGuids.set(item, true);
    } else {
      itemsGuids.delete(item);
      itemsQnt.delete(item);
    }
    console.log(itemsGuids);
    setGuidsPE(itemsGuids);
    setQuantPE(itemsQnt);
    setShowBTNFinalizar(guidsPE.size > 0 ? true : false);
  }

  function handleClickFinalizar(e) {
    console.log(guidsPE);
    props.history.push({
      pathname: "/app/planos/contratar/forma-pagamento",
      state: {
        guidspesolo: guidsPE,
        guidsquantity: quantPE
      }
    });
  }

  return (
    <>
      <LoadingGrid display={loadingGrid} />
      <div style={{display: (!loadingGrid ? "" : "none"), height: "100vh", position: "relative"}}>
        <MeuPlanoAtual />

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <a className={classes.btnPrincipal} href="/app/multas-nao-acompanhadas">
                  <p className={classes.btnStyle}>
                      Meus Pedidos
                  </p>
              </a>
            </GridItem>
        </GridContainer>

        {/* <h2 className={classes.headerTitle}>Ofertas especiais</h2> */}
        <Alert severity={"info"} className={classes.messageInfos}>{ pe.length > 0 ? "Aproveite as ofertas especiais e COMPRE AGORA!" : "No momento não estamos com nenhuma oferta, mas fique ligado, em breve estaremos com itens disponíveis!"}</Alert>
        <GridContainer>
          {pe.map((prodExtra, i) => (
            <CardPlano
              key={`card-${i}`}
              prod={prodExtra}
              checkPE={checkboxPE}
              changeQuant={handleQuantityChange}
              changeCheck={handleChangeCheckbox}
            />
          ))}
        </GridContainer>
        {showBTNFinalizar ? (
          <Fab variant="extended" classes={{root: !matches ? classes.fabButton : classes.fabButtonMobile}} onClick={handleClickFinalizar}>
            Finalizar compra
          </Fab>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
