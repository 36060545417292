const serverApi =
{ 
    url : 'https://apicondutorlegal.azurewebsites.net',
    urlPHP : 'https://apicondutorlegal.azurewebsites.net/apicdl',
    //url : 'https://api.labet.com.br/CondutorLegalAPI',
    //urlPHP : 'https://wscl.labest.com.br',
    //url : 'https://apicdlrelease.azurewebsites.net',
    //url : 'http://localhost/GT.Api',
    versao : '/api/appUsuario/v1' 
}


export {serverApi}